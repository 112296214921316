<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the vapor pressure for ethanol (in
        <stemble-latex content="$\text{kPa}$)" />
        at
        <stemble-latex content="$19.0^{\circ}\text{C}$" />
        for a solution prepared by dissolving
        <number-value :value="massSolute" unit="\text{g}" />
        of cinnamaldehyde,
        <chemical-latex content="C9H8O," />
        in
        <number-value :value="massSolvent" unit="\text{g}" />
        of ethanol,
        <stemble-latex content="$\ce{C2H5OH}.$" />
        The vapor pressure of pure ethanol at
        <stemble-latex content="$19.0\,^\circ\text{C}$" />
        is
        <stemble-latex content="$5.30\,\text{kPa.}$" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{P}:$"
        append-text="$\text{kPa}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220S4Q1',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    massSolute() {
      return this.taskState.getValueBySymbol('massSolute').content;
    },
    massSolvent() {
      return this.taskState.getValueBySymbol('massSolvent').content;
    },
  },
};
</script>
